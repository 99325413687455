@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&display=swap');

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swiper-button-next, 
.swiper-button-prev {
  color: #7c885f !important;
}
@media (max-width: 900px) {
  .swiper-container:nth-child(2) {
    max-width: 78%;
    margin-left: 0;
  }
}

@media (min-width: 901px) {
  .swiper-container:first-child {
    max-height: 500px;
    width: 100%;
  }
}
@media (max-width: 733px) {
  .swiper-container:first-child {
    display: none;
  }
  .swiper-container:nth-child(2) {
    margin: 0 auto;
  }
}

.listing-link {
  width: 100%;
  text-align: center;
  font-size: 24px;
  text-decoration: none;
  margin: 16px 0 0 0;
  color: #b66e2d;
}
.listing-link:hover, 
.listing-link:active {
  color: #9d8f66;
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 1000px;
}
.loading-h1 {
  font-size: 52px;
  text-align: center;
  font-family: "Playfair Display", serif;
  color: #586a3a;
}
.loading-h2 {
  font-size: 28px;
  text-align: center;
  font-family: "Playfair Display", serif;
  color: #586a3a;
  margin: 0;
}
.loading-btn {
  background-color: #586a3a;
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  margin: 32px 0 160px 0;
  text-decoration: none;
  font-size: 24px;
  font-family: "Playfair Display", serif;
}
.loading-btn:visited {
  color: #fff;
}
.loading-btn:hover,
.loading-btn:active {
  background-color: #84946a;
}
.flag {
  width: 40px;
  height: auto;
}
.nav-transition {
  transition: all 600ms ease-in-out;
}
.menu-item-scrolled {
  font-size: 14px;
}
.menu-item-unscrolled {
  font-size: 24px;
}
.brandmark-scrolled {
  height: 40px;
}
.brandmark-unscrolled {
  height: 60px;
}
@media (max-width: 767px) {
  .logo-unscrolled {
    height: 40px;
  }
}
@media (min-width: 768px) {
  .logo-unscrolled {
    height: 60px;
  }
}
.logo-scrolled {
  height: 40px;
}
.nav-scrolled {
  height: 80px;
}
.nav-unscrolled {
  height: auto;
}